.wpb-js-composer .vc_tta-container {
    margin-bottom: 21.73913043px;
}
.wpb-js-composer .vc_tta.vc_general {
    font-size: 1em;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panels,
.wpb-js-composer .vc_tta.vc_general .vc_tta-panels-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel {
    display: block;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel-heading {
    border: solid transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel-title {
    margin: 0;
    line-height: 1;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel-title > a {
    background: 0 0;
    display: block;
    padding: 14px 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    position: relative;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel-title > a:focus,
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel-title > a:hover {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel-body {
    border: solid transparent;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding: 14px 20px;
    display: none;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: padding 0.2s ease-in-out;
    -o-transition: padding 0.2s ease-in-out;
    transition: padding 0.2s ease-in-out;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel-body > :last-child {
    margin-bottom: 0;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active {
    display: block;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-panel.vc_active .vc_tta-panel-body {
    display: block;
}
.wpb-js-composer
    .vc_tta.vc_general
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a:hover {
    cursor: default;
}
.wpb-js-composer
    .vc_tta.vc_general
    .vc_tta-panel.vc_animating
    .vc_tta-panel-body {
    display: block;
    min-height: 0;
}
.wpb-js-composer
    .vc_tta.vc_general.vc_tta-o-all-clickable
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    cursor: pointer;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tabs-container {
    display: block;
    position: relative;
    z-index: 3;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tabs-list {
    list-style-type: none;
    display: block;
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab {
    display: inline-block;
    padding: 0;
    margin: 0;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab > a {
    padding: 14px 20px;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: solid transparent;
    position: relative;
    text-decoration: none;
    color: inherit;
    -webkit-transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
        border 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
        border 0.2s ease-in-out;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
        border 0.2s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab > a:hover {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab.vc_active > a {
    cursor: default;
    text-decoration: none;
    color: inherit;
    -webkit-transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab.vc_active > a:hover {
    cursor: default;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab.vc_active > a::after,
.wpb-js-composer .vc_tta.vc_general .vc_tta-tab.vc_active > a::before {
    display: none;
    content: '';
    position: absolute;
    border-width: inherit;
    border-color: inherit;
    border-style: inherit;
    width: 100vw;
    height: 200vw;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-icon {
    font-size: 1.15em;
    line-height: 0;
    display: inline;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-icon::before {
    display: inline;
}
.wpb-js-composer
    .vc_tta.vc_general
    .vc_tta-title-text:not(:empty):not(:first-child),
.wpb-js-composer .vc_tta.vc_general .vc_tta-title-text:not(:empty) ~ * {
    margin-left: 14px;
}
.wpb-js-composer .vc_tta.vc_general .vc_tta-title-text:empty {
    display: inline-block;
}
.wpb-js-composer .vc_tta-icon.fa,
.wpb-js-composer .vc_tta-icon.vc_li {
    vertical-align: middle;
}
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-tabs-container {
    display: none;
}
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tabs-container {
    display: none;
}
.vc_non_responsive .wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tabs-container,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-tabs
    .vc_tta-tabs-container {
    display: block;
}
.vc_non_responsive .wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel-heading,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-tabs
    .vc_tta-panel-heading {
    display: none;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tabs-container {
        display: block;
    }
    .wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel-heading {
        display: none;
    }
}
.wpb-js-composer .vc_tta.vc_tta-shape-square .vc_tta-panel-body {
    min-height: 0;
}
.wpb-js-composer .vc_tta.vc_tta-shape-square .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-shape-square .vc_tta-panel-heading {
    border-radius: 0;
}
.wpb-js-composer .vc_tta.vc_tta-shape-square .vc_tta-tabs-container {
    margin: 0;
}
.wpb-js-composer .vc_tta.vc_tta-shape-square .vc_tta-tab > a {
    border-radius: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-shape-square.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-shape-square.vc_tta-tabs
    .vc_tta-panels {
    border-radius: 0;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-shape-square.vc_tta-tabs .vc_tta-panels {
        border-radius: 0;
    }
}
.wpb-js-composer
    .vc_tta.vc_tta-shape-square.vc_tta-o-no-fill
    .vc_tta-panel-body {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta.vc_tta-shape-square.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel.vc_active
    + .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-shape-square.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel.vc_active
    + .vc_tta-panel:not(:first-child):not(:last-child)
    .vc_tta-panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta.vc_tta-shape-rounded .vc_tta-panel-body {
    min-height: 10px;
}
.wpb-js-composer .vc_tta.vc_tta-shape-rounded .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-shape-rounded .vc_tta-panel-heading {
    border-radius: 5px;
}
.wpb-js-composer .vc_tta.vc_tta-shape-rounded .vc_tta-tabs-container {
    margin: 5px;
}
.wpb-js-composer .vc_tta.vc_tta-shape-rounded .vc_tta-tab > a {
    border-radius: 5px;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-shape-rounded.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-shape-rounded.vc_tta-tabs
    .vc_tta-panels {
    border-radius: 5px;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-shape-rounded.vc_tta-tabs .vc_tta-panels {
        border-radius: 5px;
    }
}
.wpb-js-composer
    .vc_tta.vc_tta-shape-rounded.vc_tta-o-no-fill
    .vc_tta-panel-body {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta.vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel.vc_active
    + .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel.vc_active
    + .vc_tta-panel:not(:first-child):not(:last-child)
    .vc_tta-panel-heading {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.wpb-js-composer .vc_tta.vc_tta-shape-round .vc_tta-panel-body {
    min-height: 4em;
}
.wpb-js-composer .vc_tta.vc_tta-shape-round .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-shape-round .vc_tta-panel-heading {
    border-radius: 2em;
}
.wpb-js-composer .vc_tta.vc_tta-shape-round .vc_tta-tabs-container {
    margin: 2em;
}
.wpb-js-composer .vc_tta.vc_tta-shape-round .vc_tta-tab > a {
    border-radius: 2em;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-shape-round.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-shape-round.vc_tta-tabs
    .vc_tta-panels {
    border-radius: 2em;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-shape-round.vc_tta-tabs .vc_tta-panels {
        border-radius: 2em;
    }
}
.wpb-js-composer
    .vc_tta.vc_tta-shape-round.vc_tta-o-no-fill
    .vc_tta-panel-body {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta.vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel.vc_active
    + .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel.vc_active
    + .vc_tta-panel:not(:first-child):not(:last-child)
    .vc_tta-panel-heading {
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
}
.wpb-js-composer
    .vc_tta-shape-rounded:not(.vc_tta-o-no-fill)
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded:not(.vc_tta-o-no-fill)
    .vc_tta-panel.vc_active
    .vc_tta-panel-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-tabs
    .vc_tta-panel-body::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-tabs
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-tabs
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-tabs
    .vc_tta-panel-body::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: '';
    display: none;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    border-style: inherit;
    border-width: inherit;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-shape-rounded.vc_tta-tabs
        .vc_tta-panel-body::after,
    .wpb-js-composer
        .vc_tta-shape-rounded.vc_tta-tabs
        .vc_tta-panel-body::before {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        content: '';
        display: none;
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        border-style: inherit;
        border-width: inherit;
    }
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:not(:first-child):not(:last-child)
    .vc_tta-panel-heading {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:not(:first-child):not(:last-child)
    .vc_tta-panel-body {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:first-child:not(:last-child)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:first-child:not(:last-child)
    .vc_tta-panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:not(:first-child):not(:last-child).vc_active
    .vc_tta-panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:first-child:not(:last-child):not(.vc_active)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:first-child:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-rounded.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round:not(.vc_tta-o-no-fill)
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round:not(.vc_tta-o-no-fill)
    .vc_tta-panel.vc_active
    .vc_tta-panel-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-shape-round.vc_tta-tabs
    .vc_tta-panel-body::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-shape-round.vc_tta-tabs
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-shape-round.vc_tta-tabs
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-shape-round.vc_tta-tabs
    .vc_tta-panel-body::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    content: '';
    display: none;
    position: absolute;
    width: 2em;
    height: 2em;
    border-radius: 2em;
    border-style: inherit;
    border-width: inherit;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta-shape-round.vc_tta-tabs .vc_tta-panel-body::after,
    .wpb-js-composer
        .vc_tta-shape-round.vc_tta-tabs
        .vc_tta-panel-body::before {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        content: '';
        display: none;
        position: absolute;
        width: 2em;
        height: 2em;
        border-radius: 2em;
        border-style: inherit;
        border-width: inherit;
    }
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:not(:first-child):not(:last-child)
    .vc_tta-panel-heading {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:not(:first-child):not(:last-child)
    .vc_tta-panel-body {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:first-child:not(:last-child)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:first-child:not(:last-child)
    .vc_tta-panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group:not(.vc_tta-o-no-fill)
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:not(:first-child):not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:not(:first-child):not(:last-child).vc_active
    .vc_tta-panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:first-child:not(:last-child):not(.vc_active)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:first-child:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-shape-round.vc_tta-o-shape-group.vc_tta-o-no-fill
    .vc_tta-panel:last-child:not(:first-child)
    .vc_tta-panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta.vc_tta-style-classic .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-style-classic .vc_tta-panel-heading {
    border-width: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-style-classic
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-style-classic
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: -1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-style-classic
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-style-classic .vc_tta-tabs-list {
    padding-left: 1px;
    padding-top: 1px;
}
.wpb-js-composer .vc_tta.vc_tta-style-classic .vc_tta-tab {
    margin-left: -1px;
    margin-top: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-style-classic .vc_tta-tab > a {
    border-width: 1px;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    border: 1px solid transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panel,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panel {
    margin: -1px;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels {
        border: 1px solid transparent;
    }
    .wpb-js-composer .vc_tta.vc_tta-style-classic.vc_tta-tabs .vc_tta-panel {
        margin: -1px;
    }
}
.wpb-js-composer .vc_tta.vc_tta-style-modern .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-style-modern .vc_tta-panel-heading {
    border-width: 1px;
    background-image: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.01) 100%
    );
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.01))
    );
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.01) 100%
    );
    background-repeat: repeat-x;
}
.wpb-js-composer
    .vc_tta.vc_tta-style-modern
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-style-modern
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: -1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-style-modern
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-style-modern .vc_tta-tabs-list {
    padding-left: 1px;
    padding-top: 1px;
}
.wpb-js-composer .vc_tta.vc_tta-style-modern .vc_tta-tab {
    margin-left: -1px;
    margin-top: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-style-modern .vc_tta-tab > a {
    border-width: 1px;
    background-image: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.01) 100%
    );
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.01))
    );
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2) 0,
        rgba(255, 255, 255, 0.01) 100%
    );
    background-repeat: repeat-x;
}
.wpb-js-composer .vc_tta.vc_tta-style-modern .vc_tta-tab.vc_active > a {
    background-clip: border-box;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    border: 1px solid transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panel,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panel {
    margin: -1px;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-style-modern.vc_tta-tabs .vc_tta-panels {
        border: 1px solid transparent;
    }
    .wpb-js-composer .vc_tta.vc_tta-style-modern.vc_tta-tabs .vc_tta-panel {
        margin: -1px;
    }
}
.wpb-js-composer .vc_tta.vc_tta-style-outline .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-style-outline .vc_tta-panel-heading {
    border-width: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: -2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: -2px;
}
.wpb-js-composer .vc_tta.vc_tta-style-outline .vc_tta-tabs-list {
    padding-left: 2px;
    padding-top: 2px;
}
.wpb-js-composer .vc_tta.vc_tta-style-outline .vc_tta-tab {
    margin-left: -2px;
    margin-top: -2px;
}
.wpb-js-composer .vc_tta.vc_tta-style-outline .vc_tta-tab > a {
    border-width: 2px;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel-body::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline
    .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta.vc_tta-style-outline .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta.vc_tta-style-outline .vc_tta-panel-body::before {
    display: block;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    border: 2px solid transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panel,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panel {
    margin: -2px;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-style-outline.vc_tta-tabs .vc_tta-panels {
        border: 2px solid transparent;
    }
    .wpb-js-composer .vc_tta.vc_tta-style-outline.vc_tta-tabs .vc_tta-panel {
        margin: -2px;
    }
}
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-style-outline.vc_tta-pageable
    .vc_tta-panel {
    margin: 0;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta.vc_tta-style-outline.vc_tta-pageable
        .vc_tta-panel {
        margin: 0;
    }
}
.wpb-js-composer .vc_tta.vc_tta-style-flat .vc_tta-panel-body,
.wpb-js-composer .vc_tta.vc_tta-style-flat .vc_tta-panel-heading {
    border-width: 0;
}
.wpb-js-composer .vc_tta-pageable .vc_tta-panel-title {
    display: none;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #4868cf;
    background-color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #4868cf;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #4868cf;
    background-color: #5472d2;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-classic .vc_tta-tab > a:hover {
    background-color: #3c5ecc;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #4868cf;
    background-color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #4868cf;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #4868cf;
    background-color: #5472d2;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #3c5ecc;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #5472d2;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #5472d2;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #5472d2;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #5472d2;
    background-color: transparent;
    color: #5472d2;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-outline .vc_tta-tab > a:hover {
    background-color: #5472d2;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #5472d2;
    background-color: transparent;
    color: #5472d2;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #5472d2;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #5472d2;
    }
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #4868cf;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #3c5ecc;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #5472d2;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #4868cf;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-blue.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #3c5ecc;
}
.wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #5472d2;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #5472d2;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #5472d2;
    }
    .wpb-js-composer
        .vc_tta-color-blue.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #00b3c0;
    background-color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #00b3c0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #00b3c0;
    background-color: #00c1cf;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #00a4b0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #00b3c0;
    background-color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #00b3c0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #00b3c0;
    background-color: #00c1cf;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #00a4b0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #00c1cf;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #00c1cf;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #00c1cf;
}
.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #00c1cf;
    background-color: transparent;
    color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #00c1cf;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #00c1cf;
    background-color: transparent;
    color: #00c1cf;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #00c1cf;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #00c1cf;
    }
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #00b3c0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #00a4b0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #00c1cf;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-turquoise.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #00b3c0;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-tab
    > a:hover {
    background-color: #00a4b0;
}
.wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #00c1cf;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #00c1cf;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-turquoise.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #00c1cf;
    }
    .wpb-js-composer
        .vc_tta-color-turquoise.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #fe5e52;
    background-color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #fe5e52;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #fe5e52;
    background-color: #fe6c61;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-classic .vc_tta-tab > a:hover {
    background-color: #fe5043;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #fe5e52;
    background-color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #fe5e52;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #fe5e52;
    background-color: #fe6c61;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #fe5043;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #fe6c61;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #fe6c61;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fe6c61;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #fe6c61;
    background-color: transparent;
    color: #fe6c61;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-outline .vc_tta-tab > a:hover {
    background-color: #fe6c61;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #fe6c61;
    background-color: transparent;
    color: #fe6c61;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #fe6c61;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #fe6c61;
    }
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #fe5e52;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #fe5043;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #fe6c61;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #fe5e52;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-pink.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #fe5043;
}
.wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #fe6c61;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #fe6c61;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #fe6c61;
    }
    .wpb-js-composer
        .vc_tta-color-pink.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #8462c0;
    background-color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #8462c0;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-violet.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #8462c0;
    background-color: #8d6dc4;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #7c57bb;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #8462c0;
    background-color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #8462c0;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-violet.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #8462c0;
    background-color: #8d6dc4;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-violet.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #7c57bb;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #8d6dc4;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #8d6dc4;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #8d6dc4;
}
.wpb-js-composer .vc_tta-color-violet.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #8d6dc4;
    background-color: transparent;
    color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #8d6dc4;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #8d6dc4;
    background-color: transparent;
    color: #8d6dc4;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #8d6dc4;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #8d6dc4;
    }
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #8462c0;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #7c57bb;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #8d6dc4;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-violet.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #8462c0;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-violet.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-violet.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #7c57bb;
}
.wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #8d6dc4;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #8d6dc4;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-violet.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #8d6dc4;
    }
    .wpb-js-composer
        .vc_tta-color-violet.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #40a8c5;
    background-color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #40a8c5;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-peacoc.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #40a8c5;
    background-color: #4cadc9;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #39a0bd;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #40a8c5;
    background-color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #40a8c5;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-peacoc.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #40a8c5;
    background-color: #4cadc9;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-peacoc.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #39a0bd;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #4cadc9;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #4cadc9;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #4cadc9;
}
.wpb-js-composer .vc_tta-color-peacoc.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #4cadc9;
    background-color: transparent;
    color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #4cadc9;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #4cadc9;
    background-color: transparent;
    color: #4cadc9;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #4cadc9;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #4cadc9;
    }
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #40a8c5;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #39a0bd;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #4cadc9;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-peacoc.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #40a8c5;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-peacoc.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-peacoc.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #39a0bd;
}
.wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #4cadc9;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #4cadc9;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-peacoc.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #4cadc9;
    }
    .wpb-js-composer
        .vc_tta-color-peacoc.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #c8bba1;
    background-color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #c8bba1;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #c8bba1;
    background-color: #cec2ab;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #c3b498;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #c8bba1;
    background-color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #c8bba1;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #c8bba1;
    background-color: #cec2ab;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #c3b498;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #cec2ab;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #cec2ab;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #cec2ab;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #cec2ab;
    background-color: transparent;
    color: #cec2ab;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #cec2ab;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #cec2ab;
    background-color: transparent;
    color: #cec2ab;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #cec2ab;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #cec2ab;
    }
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #c8bba1;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #c3b498;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #cec2ab;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #c8bba1;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-chino.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #c3b498;
}
.wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #cec2ab;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #cec2ab;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-chino.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #cec2ab;
    }
    .wpb-js-composer
        .vc_tta-color-chino.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #484152;
    background-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #484152;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-tab
    > a {
    border-color: #484152;
    background-color: #50485b;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #413a4a;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #484152;
    background-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #484152;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-mulled-wine.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #484152;
    background-color: #50485b;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #413a4a;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #50485b;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #50485b;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-tab
    > a {
    border-color: #50485b;
    background-color: transparent;
    color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #50485b;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #50485b;
    background-color: transparent;
    color: #50485b;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #50485b;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #50485b;
    }
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #484152;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #413a4a;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #50485b;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-mulled-wine.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #484152;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-tab
    > a:hover {
    background-color: #413a4a;
}
.wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #50485b;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #50485b;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-mulled-wine.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #50485b;
    }
    .wpb-js-composer
        .vc_tta-color-mulled-wine.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #69d294;
    background-color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #69d294;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-vista-blue.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #69d294;
    background-color: #75d69c;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #5dcf8b;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #69d294;
    background-color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #69d294;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-vista-blue.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #69d294;
    background-color: #75d69c;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #5dcf8b;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #75d69c;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #75d69c;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #75d69c;
}
.wpb-js-composer .vc_tta-color-vista-blue.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #75d69c;
    background-color: transparent;
    color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #75d69c;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #75d69c;
    background-color: transparent;
    color: #75d69c;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #75d69c;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #75d69c;
    }
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #69d294;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #5dcf8b;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #75d69c;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-vista-blue.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #69d294;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-tab
    > a:hover {
    background-color: #5dcf8b;
}
.wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #75d69c;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #75d69c;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-vista-blue.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #75d69c;
    }
    .wpb-js-composer
        .vc_tta-color-vista-blue.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f6b859;
    background-color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f6b859;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #f6b859;
    background-color: #f7be68;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #f5b14b;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f6b859;
    background-color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f6b859;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #f6b859;
    background-color: #f7be68;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #f5b14b;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f7be68;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f7be68;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #f7be68;
}
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #f7be68;
    background-color: transparent;
    color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #f7be68;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #f7be68;
    background-color: transparent;
    color: #f7be68;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f7be68;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f7be68;
    }
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #f6b859;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f5b14b;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f7be68;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #f6b859;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-orange.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #f5b14b;
}
.wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #f7be68;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f7be68;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-orange.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f7be68;
    }
    .wpb-js-composer
        .vc_tta-color-orange.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #4d9ae1;
    background-color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #4d9ae1;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #4d9ae1;
    background-color: #067db3;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-classic .vc_tta-tab > a:hover {
    background-color: #4092df;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #4d9ae1;
    background-color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #4d9ae1;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #4d9ae1;
    background-color: #067db3;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #4092df;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #067db3;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #067db3;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #067db3;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #067db3;
    background-color: transparent;
    color: #067db3;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-outline .vc_tta-tab > a:hover {
    background-color: #067db3;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #067db3;
    background-color: transparent;
    color: #067db3;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #067db3;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #067db3;
    }
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #4d9ae1;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #4092df;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #067db3;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #4d9ae1;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #4092df;
}
.wpb-js-composer .vc_tta-color-sky.vc_tta-style-flat .vc_tta-tab.vc_active > a {
    background-color: #067db3;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #067db3;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sky.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #067db3;
    }
    .wpb-js-composer
        .vc_tta-color-sky.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #66a038;
    background-color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #66a038;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #66a038;
    background-color: #6dab3c;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #5f9434;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #66a038;
    background-color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #66a038;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #66a038;
    background-color: #6dab3c;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-green.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #5f9434;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #6dab3c;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #6dab3c;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #6dab3c;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #6dab3c;
    background-color: transparent;
    color: #6dab3c;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #6dab3c;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #6dab3c;
    background-color: transparent;
    color: #6dab3c;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #6dab3c;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #6dab3c;
    }
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #66a038;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #5f9434;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #6dab3c;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #66a038;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-green.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-green.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #5f9434;
}
.wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #6dab3c;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #6dab3c;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-green.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #6dab3c;
    }
    .wpb-js-composer
        .vc_tta-color-green.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f3443f;
    background-color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f3443f;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-juicy-pink.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #f3443f;
    background-color: #f4524d;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #f23630;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f3443f;
    background-color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f3443f;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-juicy-pink.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #f3443f;
    background-color: #f4524d;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #f23630;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f4524d;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f4524d;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #f4524d;
}
.wpb-js-composer .vc_tta-color-juicy-pink.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #f4524d;
    background-color: transparent;
    color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #f4524d;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #f4524d;
    background-color: transparent;
    color: #f4524d;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f4524d;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f4524d;
    }
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #f3443f;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f23630;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f4524d;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-juicy-pink.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #f3443f;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-tab
    > a:hover {
    background-color: #f23630;
}
.wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #f4524d;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f4524d;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-juicy-pink.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f4524d;
    }
    .wpb-js-composer
        .vc_tta-color-juicy-pink.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f68a59;
    background-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f68a59;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-tab
    > a {
    border-color: #f68a59;
    background-color: #f79468;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #f57f4b;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f68a59;
    background-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f68a59;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-sandy-brown.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #f68a59;
    background-color: #f79468;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #f57f4b;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f79468;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f79468;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-tab
    > a {
    border-color: #f79468;
    background-color: transparent;
    color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #f79468;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #f79468;
    background-color: transparent;
    color: #f79468;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f79468;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f79468;
    }
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #f68a59;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f57f4b;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f79468;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-sandy-brown.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #f68a59;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-tab
    > a:hover {
    background-color: #f57f4b;
}
.wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #f79468;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f79468;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-sandy-brown.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f79468;
    }
    .wpb-js-composer
        .vc_tta-color-sandy-brown.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #b474b6;
    background-color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #b474b6;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-purple.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #b474b6;
    background-color: #b97ebb;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #ae6ab0;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #b474b6;
    background-color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #b474b6;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-purple.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #b474b6;
    background-color: #b97ebb;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-purple.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-tab
    > a:hover {
    background-color: #ae6ab0;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #b97ebb;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #b97ebb;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #b97ebb;
}
.wpb-js-composer .vc_tta-color-purple.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #b97ebb;
    background-color: transparent;
    color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-tab
    > a:focus,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #b97ebb;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #b97ebb;
    background-color: transparent;
    color: #b97ebb;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #b97ebb;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #b97ebb;
    }
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #b474b6;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #ae6ab0;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #b97ebb;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-purple.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #b474b6;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-purple.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-purple.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #ae6ab0;
}
.wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #b97ebb;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #b97ebb;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-purple.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #b97ebb;
    }
    .wpb-js-composer
        .vc_tta-color-purple.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #222;
    background-color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #222;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #222;
    background-color: #2a2a2a;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #1b1b1b;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #222;
    background-color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #222;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #222;
    background-color: #2a2a2a;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-black.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #1b1b1b;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #2a2a2a;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #2a2a2a;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #2a2a2a;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #2a2a2a;
    background-color: transparent;
    color: #2a2a2a;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #2a2a2a;
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #2a2a2a;
    background-color: transparent;
    color: #2a2a2a;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #2a2a2a;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #2a2a2a;
    }
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #222;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #1b1b1b;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #2a2a2a;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #222;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-black.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-black.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #1b1b1b;
}
.wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #2a2a2a;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #2a2a2a;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-black.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #2a2a2a;
    }
    .wpb-js-composer
        .vc_tta-color-black.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #e3e3e3;
    background-color: #ebebeb;
    color: #666;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-tab > a:hover {
    background-color: #dcdcdc;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #e3e3e3;
    background-color: #ebebeb;
    color: #666;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #dcdcdc;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #e3e3e3;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #ebebeb;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #ebebeb;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #ebebeb;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #ebebeb;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #ebebeb;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #ebebeb;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #ebebeb;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #e3e3e3;
    background-color: transparent;
    color: #ebebeb;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-outline .vc_tta-tab > a:hover {
    background-color: #ebebeb;
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #ebebeb;
    background-color: transparent;
    color: #ebebeb;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #ebebeb;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #ebebeb;
    }
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #f8f8f8;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #e3e3e3;
    color: #666;
}
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #dcdcdc;
}
.wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #ebebeb;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #ebebeb;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #ebebeb;
    }
    .wpb-js-composer
        .vc_tta-color-grey.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f7f7f7;
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f7f7f7;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f7f7f7;
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f7f7f7;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-classic .vc_tta-tab > a {
    border-color: #f7f7f7;
    background-color: #fff;
    color: #666;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-classic .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-tab
    > a:hover {
    background-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-classic.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f7f7f7;
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f7f7f7;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #f7f7f7;
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #f7f7f7;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-modern .vc_tta-tab > a {
    border-color: #f7f7f7;
    background-color: #fff;
    color: #666;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-modern .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-white.vc_tta-style-modern .vc_tta-tab > a:hover {
    background-color: #f0f0f0;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern
    .vc_tta-tab.vc_active
    > a {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels {
    background-color: #f8f8f8;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #f0f0f0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels {
        background-color: #f8f8f8;
    }
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #f0f0f0;
    }
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-modern.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading {
    border-color: #f7f7f7;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-title
    > a:hover {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    border-color: #fff;
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: transparent;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel
    .vc_tta-panel-body::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel-heading:focus
    .vc_tta-controls-icon::before,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-panel-heading:hover
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #fff;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-outline .vc_tta-tab > a {
    border-color: #f7f7f7;
    background-color: transparent;
    color: #fff;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-outline .vc_tta-tab > a:focus,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-tab
    > a:hover {
    background-color: #fff;
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline
    .vc_tta-tab.vc_active
    > a {
    border-color: #fff;
    background-color: transparent;
    color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels {
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels::before {
    border-color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels {
        background-color: transparent;
    }
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels,
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::after,
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels::before {
        border-color: #fff;
    }
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-outline.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading {
    background-color: #fafafa;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:focus,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-heading:hover {
    background-color: #f2f2f2;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading {
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-panel
    .vc_tta-panel-body {
    background-color: #fff;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat:not(.vc_tta-o-no-fill)
    .vc_tta-panel-body {
    color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::after,
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_active
    .vc_tta-panel-heading
    .vc_tta-controls-icon::before {
    border-color: #666;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_tta-tab > a {
    background-color: #fafafa;
    color: #666;
}
.wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_tta-tab > a:focus,
.wpb-js-composer .vc_tta-color-white.vc_tta-style-flat .vc_tta-tab > a:hover {
    background-color: #f2f2f2;
}
.wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat
    .vc_tta-tab.vc_active
    > a {
    background-color: #fff;
    color: #666;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels {
    background-color: #fff;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-color-white.vc_tta-style-flat.vc_tta-tabs
    .vc_tta-panels
    .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels {
        background-color: #fff;
    }
    .wpb-js-composer
        .vc_tta-color-white.vc_tta-style-flat.vc_tta-tabs
        .vc_tta-panels
        .vc_tta-panel-body {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer .vc_tta.vc_tta-o-no-fill .vc_tta-panels .vc_tta-panel-body {
    border-color: transparent;
    background-color: transparent;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta.vc_tta-o-no-fill.vc_tta-tabs
    .vc_tta-panels,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta.vc_tta-o-no-fill.vc_tta-tabs
    .vc_tta-panels {
    border-color: transparent;
    background-color: transparent;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-panels {
        border-color: transparent;
        background-color: transparent;
    }
}
.wpb-js-composer .vc_tta.vc_tta-o-no-fill.vc_tta-tabs .vc_tta-tabs-container {
    margin: 0;
}
.wpb-js-composer
    .vc_tta.vc_tta-o-no-fill.vc_tta-tabs-position-bottom
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta.vc_tta-o-no-fill.vc_tta-tabs-position-top
    .vc_tta-panel-body {
    padding-left: 0;
    padding-right: 0;
}
.wpb-js-composer
    .vc_tta.vc_tta-o-no-fill.vc_tta-tabs-position-left
    .vc_tta-panel-body,
.wpb-js-composer
    .vc_tta.vc_tta-o-no-fill.vc_tta-tabs-position-right
    .vc_tta-panel-body {
    padding-top: 0;
    padding-bottom: 0;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-1
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-1
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-1
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-1
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 1px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-1 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -1px;
    margin-bottom: 0;
    margin-left: -1px;
    margin-right: 0;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-1 .vc_tta-tab {
    margin-top: 1px;
    margin-bottom: 0;
    margin-left: 1px;
    margin-right: 0;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-2
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-2
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-2
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-2
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 2px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-2 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-right: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-2 .vc_tta-tab {
    margin-top: 1px;
    margin-bottom: 1px;
    margin-left: 1px;
    margin-right: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-3
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-3
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 3px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-3
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-3
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 3px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-3 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -2px;
    margin-bottom: -1px;
    margin-left: -2px;
    margin-right: -1px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-3 .vc_tta-tab {
    margin-top: 2px;
    margin-bottom: 1px;
    margin-left: 2px;
    margin-right: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-4
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-4
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 4px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-4
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-4
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 4px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-4 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: -2px;
    margin-right: -2px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-4 .vc_tta-tab {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-5
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-5
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-5
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-5
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 5px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-5 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -3px;
    margin-bottom: -2px;
    margin-left: -3px;
    margin-right: -2px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-5 .vc_tta-tab {
    margin-top: 3px;
    margin-bottom: 2px;
    margin-left: 3px;
    margin-right: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-10
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-10
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-10
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-10
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 10px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-10 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-10 .vc_tta-tab {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-15
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-15
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-15
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-15
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 15px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-15 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -8px;
    margin-bottom: -7px;
    margin-left: -8px;
    margin-right: -7px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-15 .vc_tta-tab {
    margin-top: 8px;
    margin-bottom: 7px;
    margin-left: 8px;
    margin-right: 7px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-20
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-20
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-20
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-20
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 20px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-20 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -10px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-20 .vc_tta-tab {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-25
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-25
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 25px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-25
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-25
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 25px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-25 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -13px;
    margin-bottom: -12px;
    margin-left: -13px;
    margin-right: -12px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-25 .vc_tta-tab {
    margin-top: 13px;
    margin-bottom: 12px;
    margin-left: 13px;
    margin-right: 12px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-30
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-30
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 30px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-30
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-30
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 30px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-30 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-30 .vc_tta-tab {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-35
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-35
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 35px;
}
.wpb-js-composer
    .vc_tta.vc_tta-spacing-35
    .vc_tta-panel.vc_active
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-spacing-35
    .vc_tta-panel:not(:last-child)
    .vc_tta-panel-heading {
    margin-bottom: 35px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-35 .vc_tta-tabs-list {
    padding: 0;
    margin-top: -18px;
    margin-bottom: -17px;
    margin-left: -18px;
    margin-right: -17px;
}
.wpb-js-composer .vc_tta.vc_tta-spacing-35 .vc_tta-tab {
    margin-top: 18px;
    margin-bottom: 17px;
    margin-left: 18px;
    margin-right: 17px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-1
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-1
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-1
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-1.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-1.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-1.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-1.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 1px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-2
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-2
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-2
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-2.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-2.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-2.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-2.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 2px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-3
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-3
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 3px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-3
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 3px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-3.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 3px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-3.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 3px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-3.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 3px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-3.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 3px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-4
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-4
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 4px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-4
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 4px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-4.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 4px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-4.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 4px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-4.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 4px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-4.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 4px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-5
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-5
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-5
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-5.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-5.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-5.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-5.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 5px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-10
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-10
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-10
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-10.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-10.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-10.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-10.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 10px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-15
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-15
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-15
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-15.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-15.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-15.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-15.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 15px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-20
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-20
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-20
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-20.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-20.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-20.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-20.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-25
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-25
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 25px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-25
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 25px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-25.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 25px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-25.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 25px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-25.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 25px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-25.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 25px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-30
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-30
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 30px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-30
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 30px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-30.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 30px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-30.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 30px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-30.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 30px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-30.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 30px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-35
    .vc_tta-panel.vc_active
    + .vc_tta-panel
    .vc_tta-panel-heading,
.wpb-js-composer
    .vc_tta.vc_tta-gap-35
    .vc_tta-panel:not(:first-child)
    .vc_tta-panel-heading {
    margin-top: 35px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-35
    .vc_tta-panel:not(:last-child):not(.vc_active)
    .vc_tta-panel-heading {
    margin-bottom: 35px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-35.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-bottom: 35px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-35.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 35px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-35.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-right: 35px;
}
.wpb-js-composer
    .vc_tta.vc_tta-gap-35.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 35px;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-tabs-container {
    overflow: hidden;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-top .vc_tta-tabs-list {
    overflow: hidden;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::before {
    right: auto;
    bottom: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::after {
    left: auto;
    bottom: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left-width: 0;
    border-bottom-width: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top.vc_tta-o-shape-group
    .vc_tta-tab:not(:first-child):not(:last-child)
    > a {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top.vc_tta-o-shape-group
    .vc_tta-tab:first-child:not(:last-child)
    > a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-top.vc_tta-o-shape-group
    .vc_tta-tab:last-child:not(:first-child)
    > a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    overflow: hidden;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-bottom .vc_tta-tabs-list {
    overflow: hidden;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::before {
    right: auto;
    top: auto;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-right-width: 0;
    border-top-width: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::after {
    left: auto;
    top: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-width: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom.vc_tta-o-shape-group
    .vc_tta-tab:not(:first-child):not(:last-child)
    > a {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom.vc_tta-o-shape-group
    .vc_tta-tab:first-child:not(:last-child)
    > a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-bottom.vc_tta-o-shape-group
    .vc_tta-tab:last-child:not(:first-child)
    > a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tabs-container {
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-top: 0;
    margin-left: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-tabs-container,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-tabs.vc_tta-tabs-position-left
        .vc_tta-tabs-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tabs-list {
    overflow: hidden;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-tabs-list,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-tabs-list {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tabs-list {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-tab {
    display: block;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-panels-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-left .vc_tta-panels {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-panel-body::before {
    right: auto;
    bottom: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left
    .vc_tta-panel-body::after {
    right: auto;
    top: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    border-top-width: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-o-shape-group
    .vc_tta-tab:not(:first-child):not(:last-child)
    > a {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-o-shape-group
    .vc_tta-tab:first-child:not(:last-child)
    > a {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-o-shape-group
    .vc_tta-tab:last-child:not(:first-child)
    > a {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-top: 0;
    margin-right: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-tabs-container,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
@media (min-width: 768px) {
    .wpb-js-composer
        .vc_tta-tabs.vc_tta-tabs-position-right
        .vc_tta-tabs-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-tabs-list {
    overflow: hidden;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-tabs-list,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-tabs-list {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
@media (min-width: 768px) {
    .wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-tabs-list {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-tab {
    display: block;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-panels-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-tabs-position-right .vc_tta-panels {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-panel-body::before {
    left: auto;
    bottom: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left-width: 0;
    border-bottom-width: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-panel-body::after,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right
    .vc_tta-panel-body::after {
    left: auto;
    top: auto;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-width: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-o-shape-group
    .vc_tta-tab:not(:first-child):not(:last-child)
    > a {
    border-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-o-shape-group
    .vc_tta-tab:first-child:not(:last-child)
    > a {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-o-shape-group
    .vc_tta-tab:last-child:not(:first-child)
    > a {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-o-no-fill
    .vc_tta-panel-body::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-o-no-fill
    .vc_tta-panel-body::before,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs[class*='vc_tta-gap']
    .vc_tta-panel-body::after,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs[class*='vc_tta-gap']
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-o-no-fill
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs.vc_tta-o-no-fill
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs[class*='vc_tta-gap']
    .vc_tta-panel-body::after,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs[class*='vc_tta-gap']
    .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta-tabs.vc_tta-o-no-fill .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta-tabs.vc_tta-o-no-fill .vc_tta-panel-body::before,
.wpb-js-composer .vc_tta-tabs[class*='vc_tta-gap'] .vc_tta-panel-body::after,
.wpb-js-composer .vc_tta-tabs[class*='vc_tta-gap'] .vc_tta-panel-body::before {
    display: none;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-o-no-fill .vc_tta-tabs-container,
.wpb-js-composer .vc_tta-tabs.vc_tta-o-no-fill .vc_tta-tabs-list,
.wpb-js-composer .vc_tta-tabs[class*='vc_tta-gap'] .vc_tta-tabs-container,
.wpb-js-composer .vc_tta-tabs[class*='vc_tta-gap'] .vc_tta-tabs-list {
    overflow: initial;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top
    .vc_tta-tab
    > a {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top
    .vc_tta-tab.vc_active
    > a {
    border-bottom-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top
    .vc_tta-tab.vc_active
    > a::before {
    display: block;
    height: auto;
    top: 100%;
    right: 100%;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top
    .vc_tta-tab.vc_active
    > a::after {
    display: block;
    height: auto;
    top: 100%;
    left: 100%;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-top: 0;
    margin-bottom: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top.vc_tta-style-classic
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top.vc_tta-style-modern
    .vc_tta-tabs-container {
    margin-bottom: -1px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top.vc_tta-style-outline
    .vc_tta-tabs-container {
    margin-bottom: -2px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top.vc_tta-style-outline:not(.vc_tta-pageable)
    .vc_tta-panels {
    border-top-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom
    .vc_tta-tab
    > a {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom
    .vc_tta-tab.vc_active
    > a {
    border-top-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom
    .vc_tta-tab.vc_active
    > a::before {
    display: block;
    height: auto;
    bottom: 100%;
    right: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom
    .vc_tta-tab.vc_active
    > a::after {
    display: block;
    height: auto;
    bottom: 100%;
    left: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-top: 0;
    margin-bottom: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom.vc_tta-style-classic
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom.vc_tta-style-modern
    .vc_tta-tabs-container {
    margin-top: -1px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom.vc_tta-style-outline
    .vc_tta-tabs-container {
    margin-top: -2px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-bottom.vc_tta-style-outline:not(.vc_tta-pageable)
    .vc_tta-panels {
    border-bottom-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left
    .vc_tta-tab
    > a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left
    .vc_tta-tab.vc_active
    > a {
    border-right-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left
    .vc_tta-tab.vc_active
    > a::before {
    display: block;
    width: auto;
    left: 100%;
    bottom: 100%;
    border-bottom: 0;
    border-top: 0;
    border-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left
    .vc_tta-tab.vc_active
    > a::after {
    display: block;
    width: auto;
    left: 100%;
    top: 100%;
    border-bottom: 0;
    border-top: 0;
    border-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left
    .vc_tta-tabs-container {
    margin-left: 0;
    margin-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-style-classic
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-style-modern
    .vc_tta-tabs-container {
    margin-right: -1px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-style-outline
    .vc_tta-tabs-container {
    margin-right: -2px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-style-outline
    .vc_tta-panels {
    border-left-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-round
    .vc_tta-panels,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-rounded
    .vc_tta-panels {
    margin-top: 0;
    border-top-left-radius: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-round
    .vc_tta-panel-body::before,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-rounded
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-round
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-rounded
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-round
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-left.vc_tta-shape-rounded
    .vc_tta-panel-body::before {
    display: none;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right
    .vc_tta-tab
    > a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right
    .vc_tta-tab.vc_active
    > a {
    border-left-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right
    .vc_tta-tab.vc_active
    > a::before {
    display: block;
    width: auto;
    right: 100%;
    bottom: 100%;
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right
    .vc_tta-tab.vc_active
    > a::after {
    display: block;
    width: auto;
    right: 100%;
    top: 100%;
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right
    .vc_tta-tabs-container {
    margin-left: 0;
    margin-right: 0;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-style-classic
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-style-modern
    .vc_tta-tabs-container {
    margin-left: -1px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-style-outline
    .vc_tta-tabs-container {
    margin-left: -2px;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-style-outline
    .vc_tta-panels {
    border-right-color: transparent;
}
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-round
    .vc_tta-panels,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-rounded
    .vc_tta-panels {
    margin-top: 0;
    border-top-right-radius: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-round
    .vc_tta-panel-body::before,
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-rounded
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-round
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-rounded
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-round
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-tabs:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill).vc_tta-tabs-position-right.vc_tta-shape-rounded
    .vc_tta-panel-body::before {
    display: none;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-controls-size-xs
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-controls-size-xs
    .vc_tta-tabs-container {
    -ms-flex-preferred-size: 10%;
    flex-basis: 10%;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-controls-size-sm
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-controls-size-sm
    .vc_tta-tabs-container {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-controls-size-md
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-controls-size-md
    .vc_tta-tabs-container {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-controls-size-lg
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-controls-size-lg
    .vc_tta-tabs-container {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-left.vc_tta-controls-size-xl
    .vc_tta-tabs-container,
.wpb-js-composer
    .vc_tta-tabs.vc_tta-tabs-position-right.vc_tta-controls-size-xl
    .vc_tta-tabs-container {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
}
.wpb-js-composer .vc_tta.vc_tta-controls-align-left .vc_tta-panel-heading,
.wpb-js-composer .vc_tta.vc_tta-controls-align-left .vc_tta-tabs-container {
    text-align: left;
}
.wpb-js-composer .vc_tta.vc_tta-controls-align-right .vc_tta-panel-heading,
.wpb-js-composer .vc_tta.vc_tta-controls-align-right .vc_tta-tabs-container {
    text-align: right;
}
.wpb-js-composer .vc_tta.vc_tta-controls-align-center .vc_tta-panel-heading,
.wpb-js-composer .vc_tta.vc_tta-controls-align-center .vc_tta-tabs-container {
    text-align: center;
}
.wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-left: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::before {
    display: none;
}
.wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-top:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill)
    .vc_tta-panels {
    border-top-left-radius: 0;
}
.wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-left: 0;
}
.vc_non_responsive
    .wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::before,
.vc_tta-o-non-responsive
    .wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::before,
.wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::before {
    display: none;
}
.wpb-js-composer
    .vc_tta-controls-align-left.vc_tta-tabs.vc_tta-tabs-position-bottom:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill)
    .vc_tta-panels {
    border-bottom-left-radius: 0;
}
.wpb-js-composer
    .vc_tta-controls-align-right.vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-tabs-container {
    margin-right: 0;
}
.wpb-js-composer
    .vc_tta-controls-align-right.vc_tta-tabs.vc_tta-tabs-position-top
    .vc_tta-panel-body::after {
    display: none;
}
.wpb-js-composer
    .vc_tta-controls-align-right.vc_tta-tabs.vc_tta-tabs-position-top:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill)
    .vc_tta-panels {
    border-top-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-controls-align-right.vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-tabs-container {
    margin-right: 0;
}
.wpb-js-composer
    .vc_tta-controls-align-right.vc_tta-tabs.vc_tta-tabs-position-bottom
    .vc_tta-panel-body::after {
    display: none;
}
.wpb-js-composer
    .vc_tta-controls-align-right.vc_tta-tabs.vc_tta-tabs-position-bottom:not([class*='vc_tta-gap']):not(.vc_tta-o-no-fill)
    .vc_tta-panels {
    border-bottom-right-radius: 0;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-has-pagination:not(.vc_tta-tabs-position-bottom) {
    padding-bottom: 60px;
}
.wpb-js-composer .vc_tta-tabs.vc_tta-has-pagination .vc_pagination {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-has-pagination.vc_tta-tabs-position-bottom {
    padding-top: 60px;
}
.wpb-js-composer
    .vc_tta-tabs.vc_tta-has-pagination.vc_tta-tabs-position-bottom
    .vc_pagination {
    top: auto;
    bottom: 100%;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}
.wpb-js-composer .vc_tta .vc_tta-controls-icon {
    display: inline-block;
    vertical-align: middle;
    height: 12px;
    width: 12px;
    position: relative;
    font-size: inherit;
    margin: 0;
}
.wpb-js-composer .vc_tta .vc_tta-controls-icon:after,
.wpb-js-composer .vc_tta .vc_tta-controls-icon:before {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.wpb-js-composer
    .vc_tta
    .vc_tta-title-text:not(:empty)
    ~ .vc_tta-controls-icon {
    margin-left: 0;
}
.wpb-js-composer
    .vc_tta
    .vc_tta-controls-icon.vc_tta-controls-icon-plus::before {
    content: '';
    display: block;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-style: solid;
    border-width: 2px 0 0 0;
}
.wpb-js-composer
    .vc_tta
    .vc_tta-controls-icon.vc_tta-controls-icon-plus::after {
    content: '';
    display: block;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 50%;
    bottom: 0;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 0 0 2px;
}
.wpb-js-composer
    .vc_tta
    .vc_tta-controls-icon.vc_tta-controls-icon-chevron::before {
    content: '';
    display: block;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg) translate(-25%, -25%);
    -ms-transform: rotate(45deg) translate(-25%, -25%);
    transform: rotate(45deg) translate(-25%, -25%);
}
.wpb-js-composer
    .vc_tta
    .vc_tta-controls-icon.vc_tta-controls-icon-triangle::before {
    content: '';
    display: block;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-style: solid;
    border-width: 6px;
    border-bottom-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    -webkit-transform: translateY(25%);
    -ms-transform: translateY(25%);
    transform: translateY(25%);
}
.wpb-js-composer
    .vc_tta
    .vc_active
    .vc_tta-controls-icon.vc_tta-controls-icon-plus::after {
    display: none;
}
.wpb-js-composer
    .vc_tta
    .vc_active
    .vc_tta-controls-icon.vc_tta-controls-icon-chevron::before {
    -webkit-transform: rotate(225deg) translate(-25%, -25%);
    -ms-transform: rotate(225deg) translate(-25%, -25%);
    transform: rotate(225deg) translate(-25%, -25%);
}
.wpb-js-composer
    .vc_tta
    .vc_active
    .vc_tta-controls-icon.vc_tta-controls-icon-triangle::before {
    -webkit-transform: rotate(180deg) translateY(25%);
    -ms-transform: rotate(180deg) translateY(25%);
    transform: rotate(180deg) translateY(25%);
}
.wpb-js-composer
    .vc_tta.vc_tta-accordion
    .vc_tta-controls-icon-position-left.vc_tta-panel-title
    > a {
    padding-left: 46px;
}
.wpb-js-composer
    .vc_tta.vc_tta-accordion
    .vc_tta-controls-icon-position-left
    .vc_tta-controls-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 20px;
}
.wpb-js-composer
    .vc_tta.vc_tta-accordion
    .vc_tta-controls-icon-position-right.vc_tta-panel-title
    > a {
    padding-right: 42px;
}
.wpb-js-composer
    .vc_tta.vc_tta-accordion
    .vc_tta-controls-icon-position-right
    .vc_tta-controls-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
}
@media (max-width: 768px) {
    .wpb-js-composer
        .vc_tta-container
        .vc_tta:not([class*='vc_tta-pageable'])
        .vc_pagination {
        display: none;
    }
}
